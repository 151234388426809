
<template>
  <div>
    <el-dialog
      :title="titles"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <div
          slot="content"
          v-decorator="[
            'content',
            {
              rules: [{ required: true, message: '正文不能为空' }],
            },
          ]"
        >
          <quillEditor
            @onChange="quillHtml"
            :content="this.myContent"
            ref="quillEditor"
          />
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          style="background-color: #556bff"
          :loading="saveType"
          @click="confirm"
          >保 存</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
 <script>
import quillEditor from "../../../../Acomponents/quillEditor/wangeditor.vue";
import imageUpload from "../../../../Acomponents/ax-image-modal/src/image-upload.vue";
import api from "../api";
// import api from "../announcementList/api";
const formList = [
  {
    label: "事项名称",
    type: "",
    model: "itemName",
    options: { placeholder: "请输入事项名称", maxLength: 50 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入事项名称" }],
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择网格" }],
  },
  {
    label: "分类",
    type: "select",
    model: "itemType",
    options: { placeholder: "请选择分类" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择分类" }],
  },

  {
    label: "正文",
    type: "",
    model: "content",
    options: {},
    col: { span: 24 },
    rules: [{ required: true, message: "正文不能为空" }],
  },
];
export default {
  components: { quillEditor, imageUpload },
  data() {
    return {
      api,
      visible: false,
      content: "",
      myContent: "",
      titles: "",
      info: {},
      saveType: false,
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },

  methods: {
    openDialog(e) {
      this.titles = e.titles;
      if (e.record) {
        this.info = e.record;
      }
      this.visible = true;
      this.$nextTick(() => {
        this.getListall();
        this.getgridTree();
      });
    },
    mapCancel() {
      this.visible = false;
      this.content = "";
      this.$refs.formBox.resetFields();
      this.$refs.quillEditor.refresh();
    },
    async confirm() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        value.flag = true;
        value.gridId = value.gridId[value.gridId.length - 1].toString();
        try {
          if (this.titles === "新增办事指南") {
            const res = await api.addGuide(value);
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.mapCancel();
              this.saveType = false;
              this.$emit("refresh");
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          } else {
            value.id = this.info.id;
            const res = await api.editGuide(value);
            if (res.status == 200) {
              this.$message.success("操作成功");
              this.mapCancel();
              this.saveType = false;
              this.$emit("refresh");
            } else {
              this.$message.error("操作失败");
              this.saveType = false;
            }
          }

          this.saveType = false;
        } catch (error) {
          console.log(error);
        }
      });
    },
    // 富文本编辑器获取内容
    quillHtml(e) {
      this.content = e;
      this.$refs.formBox.setFieldsValue({
        content: this.content,
      });
    },
    // 列表查询
    async getListall() {
      try {
        const res = await api.guideListall();

        let options = res.data.map((res) => {
          return { label: res.typeName, value: res.id };
        });
        this.$refs.formBox.setOptions(["itemType"], "options", options);
      } catch (error) {
        console.log(error);
      }
    },
    // 列表网格查询
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.formBox.setFormItemProp("gridId", {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择网格",
              changeOnSelect: true,
            },
          });
          if (this.titles === "编辑办事指南") {
            let grid = this.findParentValues(options, this.info.gridId);
            this.$refs.quillEditor.assignment(this.info.content)
            this.$refs.formBox.setFieldsValue({ ...this.info, gridId: grid });
          }
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 通过value找父级value
    findParentValues(children, targetValue, parentValues = []) {
      for (let child of children) {
        if (child.value === targetValue) {
          // 如果当前节点是我们要找的，返回所有父级value（包括自身）
          return [...parentValues, child.value];
        }
        if (child.children && child.children.length > 0) {
          // 递归查找子节点
          const result = this.findParentValues(child.children, targetValue, [
            ...parentValues,
            child.value,
          ]);
          if (result) {
            return result;
          }
        }
      }
      // 如果没有找到，返回null或空数组
      return null;
    },
  },

  created() {},

  mounted() {},
};
</script>
 <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>